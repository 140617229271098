import Navbar from '../components/navbar.js';
import Landing from '../components/landing.js';
import MapBoxMai from '../components/map/map.js'
import Loader from "react-loader-spinner";
import { Fade } from '@material-ui/core';
import Footer from '../components/footer.js';
import ReactGA from 'react-ga4';
import {useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';

function Home(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  const meta = {
    title: 'Worlds Apart | Mai Kim Le',
  };

  const [landAnimationEnd, setLandAnimationEnd] = useState(false);

  return (
    <div>
      <DocumentMeta {...meta}/>
      {!props.dataLoadad ?
      <div className="data_loader">
        <Loader
          type="TailSpin"
          color="#2D545D"
          height={60}
          width={60}
          timeout={3000} //3 secs
        />
      </div>
      :
      <Fade in={props.dataLoadad}>
        <div>
          <Navbar socialMedia={props.landingData.socialMedia} images={props.images}/>
          <Landing
            title={props.landingData.title}
            preTitle={props.landingData.preTitle.exploreText}
            author={props.landingData.subtitle.authorName}
            subtitle={props.landingData.subtitle.subtitle}
            excerpt={props.landingData.subtitle.excerpt}
            images={props.images}
            setLandAnimationEnd={setLandAnimationEnd}
            landAnimationEnd={landAnimationEnd}
          />
          <MapBoxMai
            mapLocationsData={props.mapLocationsData}
            geoData={props.geoData}
            geoDataTx={props.geoDataTx}
            landAnimationEnd={landAnimationEnd}
          />
          <Footer socialMedia={props.landingData.socialMedia} images={props.images}/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default Home;
