import {useEffect } from 'react';
import Navbar from '../components/navbar.js';
import Loader from "react-loader-spinner";
import { Fade } from '@material-ui/core';
import Footer from '../components/footer.js';
import commonStyles from './css-modules/common.module.css';
import styles from './css-modules/contact.module.css';
import PageTitle from '../components/pageTitle.js';
import ReactGA from 'react-ga4';
import DocumentMeta from 'react-document-meta';
import { FaInstagram, FaFacebookF, FaTwitter, FaPhoneAlt } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

function Contact(props) {

  const meta = {
    title: 'Worlds Apart | Contact Us',
  };

 useEffect(() => {
   ReactGA.send({ hitType: "pageview", page: "/contact"});
 }, []);

  return (
    <div>
      <DocumentMeta {...meta}/>
      {!props.dataLoadad ?
      <div className="data_loader">
        <Loader
          type="TailSpin"
          color="#2D545D"
          height={60}
          width={60}
          timeout={3000} //3 secs
        />
      </div>
      :
      <Fade in={props.dataLoadad}>
        <div>
          <Navbar socialMedia={props.landingData.socialMedia} images={props.images}/>
          <PageTitle title='Contact' images={props.images}/>
          <div className={commonStyles.content_container}>
            <div className={styles.contact_section}>
              <div className={styles.form_section}>
                <p className={styles.contact_heading}>Contact</p>
                <p className={styles.contact_value} style={{cursor: 'pointer'}} onClick={() => window.open('mailto:hello@maikimle.com')}><FiMail/> <b>Email:</b> hello@maikimle.com</p>
                <p className={styles.contact_value}><FaPhoneAlt/> <b>Phone:</b> +1 617 297 7409</p>
                <p className={styles.contact_heading}>Reach out on Social Media</p>
                <p className={styles.contact_value} style={{cursor: 'pointer'}} onClick={() => window.open('https://www.instagram.com/mai_unplugged/')}><FaInstagram/> Instagram</p>
                <p className={styles.contact_value} style={{cursor: 'pointer'}} onClick={() => window.open('https://www.facebook.com/Mai.Unplugged/')}><FaFacebookF/> Facebook</p>
                <p className={styles.contact_value} style={{cursor: 'pointer'}} onClick={() => window.open('https://twitter.com/mai_unplugged')}><FaTwitter/> Twitter</p>
              </div>
              <div className={styles.illustration_section}>
                <div className={styles.image_section}>
                  <img className={styles.photo_fade} alt="Young Mai in Thai Refugee Camp" src='https://res.cloudinary.com/worlds-apart/image/upload/v1641447718/map_photos/Mai_in_Thai_camp_dfkht6.jpg' />
                  <img className={styles.photo_fade} alt="Mai at her Bowdoin College graduation ceremony" src='https://res.cloudinary.com/worlds-apart/image/upload/v1641585149/919228ad-800c-476d-a613-dabf88aec61f_amm3gc.jpg' />
                  <img className={styles.photo_fade} alt="" src='https://res.cloudinary.com/worlds-apart/image/upload/v1641584773/031765FB-4D7D-41AC-9567-DC270EB58C96_s1jxe1.jpg' />
                </div>
              </div>
            </div>
          </div>
          <Footer socialMedia={props.landingData.socialMedia} images={props.images}/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default Contact;
