import styles from './css-modules/landing.module.css';
import classNames from 'classnames';
import { BiChevronDown } from "react-icons/bi";
import gsap, {TimelineMax, Power3, TweenMax, Sine} from 'gsap';
import {useEffect, createRef} from 'react';


function onMouseMove(factorX, factorY, items){
  items.forEach((item, i) => {
      if(item.el.current) {
        let x = Math.round(0.5 * item.shift * Math.round(factorX * 100) / 100) * 2;
        let y = Math.round(0.5 * Math.max(-50, item.shift / 4 * (Math.round(factorY * 100) / 100))) * 2;
        TweenMax.to(item.el.current, 2, {
            x: x,
            y: y,
            ease: Sine.easeOut
        });
      }
  });
}

function Landing(props) {

  const containerRef = createRef();
  const strokeRef = createRef();
  const paintingRef = createRef();

  useEffect(() => {
    props.setLandAnimationEnd(false);
    const tl = new TimelineMax({ repeat: -1, repeatDelay: 1 });
    tl.fromTo(document.getElementById("md_arrow"), 0.75, {
        opacity : '1',
        y: 0,
    }, {
        opacity : 0,
        y: 10,
        ease: Power3.easeInOut,
    }, 0)
    .fromTo(document.getElementById("md_arrow"), 0.75, {
        opacity : '0',
        y: '-15'
    }, {
        opacity : 1,
        y: '0',
        ease: Power3.easeInOut,
    }, 0.75);

    gsap.fromTo(paintingRef.current, {scale: 1.05}, {scale: 1, duration: 1.5});
    gsap.fromTo(strokeRef.current, {scale: 1.04, x: 25}, {scale: 1, x:0, duration: 1.75, onComplete: setAnimEnd});
    return () => {
      tl.kill();
    }
  }, []);

  useEffect(() => {
    containerRef.current.addEventListener('mousemove', (event)=>{
      const factorX = -2 * (( event.clientX / window.innerWidth) - 0.5);
      const factorY = -2 * (( (event.clientY - 100) / (window.innerHeight - 100)) - 0.5);
      onMouseMove(factorX, factorY, [{el : strokeRef, shift : 15}]);
    });
  }, [props.landAnimationEnd]);

  const setAnimEnd = () => {
    props.setLandAnimationEnd(true);
  }

  return (
    <div className={styles.landing_container} ref={containerRef}>
      <div className={styles.background_container}></div>
      <div className={styles.painting_container}>
        <img ref={paintingRef} className={styles.painting_image} src={props.images.painting}/>
      </div>
      <div className={styles.pattern_container}>
        <img className={styles.pattern_image} src={props.images.brushedAlum}/>
        <div className={styles.pattern_overlay}></div>
      </div>
      <div className={styles.brush_stroke_container}>
        <img ref={strokeRef} className={styles.brush_stroke} src={props.images.brush}/>
      </div>
      <div className={styles.content_container}>
        <p className={classNames(styles.preTitle, "Lato", "white")}>{props.preTitle}</p>
        <h1 className={classNames(styles.title, "Libre", "white")}>{props.title}</h1>
        <h2 className={classNames(styles.author, "Lato", "white")}>{props.author}</h2>
        <h3 className={classNames(styles.subtitle, "Lato", "yellow")}>{props.subtitle}</h3>
        <p className={classNames(styles.excerpt, "handWriting", "white")}>{props.excerpt}</p>
        <div className={styles.chevron_container}>
          <p className={classNames(styles.chev_text, "Lato", "white")}>Explore</p>
          <div className={styles.chevron_icon_container}>
            <BiChevronDown id="md_arrow" className={styles.scroll_down_icon_arrow}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
