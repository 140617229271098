import {useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {locsAndZoom} from './maiLocs.js';
import styles from './CSS-modules/map.module.css';
import HomePara from '../home_para.js';

import {
  drawRoute,
  killRoutes,
  drawPulseCircle,
  getD3,
  render
} from './mapDrawFuncs.js';

const nIdx = 15;

function MapBoxMai(props) {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const svg = useRef(null);

  const [loc, setLoc] = useState(0);

  // Parameters defining the mapbox map
  const mapboxParams = {
    accessToken: 'pk.eyJ1Ijoic2FhZGtoYWxpZDkwIiwiYSI6ImNqbHJuNzRxNTA2bHMzd25tNTJ5dHlhb20ifQ.JG2i_ohQ9kuIoSotC9UvWA',
    center: [105.4901, 10.3327], // picked a center coordinate for Multan city
    initZoomVal: 5.0,
  }

  mapboxgl.accessToken = mapboxParams.accessToken;

  useEffect(() => {
    if (props.landAnimationEnd) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current, // container id
        style: 'mapbox://styles/saadkhalid90/cksbq3ob71k6w19mu6ykdl8ka', // custom style (Mai)   ?optimize=true
        center: mapboxParams.center,
        zoom: mapboxParams.initZoomVal, // zoom value has been ascertained manually, is there a way to automate this given the locations (do some math with the centroid and the extent)
        dragPan: false
      })
      // disabling and enabling some map features
      map.current.scrollZoom.disable(); // disable zoom on pinch
      map.current.doubleClickZoom.disable(); // disable double click zoom
      // appending svg for points and paths
      const container = map.current.getCanvasContainer();
      // appending an svg to that container
      svg.current = d3.select(container).append("svg").classed(styles.svg, true);
      drawPulseCircle(svg.current, locsAndZoom[loc%nIdx], getD3(map.current));
    }
  }, [props.landAnimationEnd]);

  useEffect(() => {
    if (map.current && svg.current) {
      map.current.easeTo({
        center: locsAndZoom[loc].loc,
        zoom: locsAndZoom[loc].zoom,
        duration: locsAndZoom[loc].txTime,
      });

      drawPulseCircle(svg.current, locsAndZoom[loc%nIdx], getD3(map.current));

      if (props.geoDataTx){
        killRoutes(svg.current);
        if (locsAndZoom[loc%nIdx].pathIdx !== null){
          drawRoute(
            svg.current,
            props.geoDataTx[locsAndZoom[loc%nIdx].pathIdx],
            locsAndZoom[loc%nIdx].pathLength,
            locsAndZoom[loc%nIdx].txTime
          )
        }
        // re-render our visualization whenever the view changes (mapbox state changes - zoom/ pan etc)
          map.current.on("viewreset", function() {
            render(map.current, props.geoDataTx, svg.current, locsAndZoom, loc, nIdx);
          });
          map.current.on("move", function() {
            render(map.current, props.geoDataTx, svg.current, locsAndZoom, loc, nIdx);
          });
      }
    }
  }, [loc]);

  const changeLoc = (locID) => {
    if (loc !== locID) {
      setLoc(locID);
    }
  }

  return (
    <div className={styles.sticky_container}>
      <div className={styles.map_info_container} id="map_paras_container">
        {props.mapLocationsData.length > 0 && props.mapLocationsData.map((data, index) =>
          <div key={index}>
            <HomePara
              heading={data.title}
              para={data.data.description}
              images={data.data.images}
              locationID={data.data.locationid}
              index={index}
              changeLoc={changeLoc}
            />
          </div>
        )}
      </div>
      <div className={styles.map_flexed_container}>
        <div className={styles.map_sticky_container}>
          <div ref={mapContainer} id="mapContainer" className={styles.mapContainer}>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapBoxMai;
