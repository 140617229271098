import * as d3 from 'd3';

const timeTrans = 1500;
const timeDelay = 0;

function drawRoute(svgContain, datum, totalPathLength, txTime) {
  // add new routes
  const route = svgContain.selectAll('path.routes')
    .data([datum])
    .enter()
    .append('path')
    .attr('class', 'routes')
    .style('stroke', 'yellow')
    .style('fill', 'none')
    .style('stroke-width', '2px')
    .attr("stroke-dasharray", d => `${totalPathLength} ${totalPathLength}`)
    .attr('stroke-dashoffset', d=> `${totalPathLength}`)
    .transition()
    .duration(txTime)
    .ease(d3.easeCubic)
    .attr('stroke-dashoffset', 0)
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round');

  return route;
}

function killRoutes(svgContain) {
  svgContain.selectAll('path.routes').remove();
}

function drawPulseCircle(svgContain, datum, projection) {
  svgContain.selectAll('circle.pulseDot').remove();
  svgContain.selectAll('circle.staticDot').remove();

  svgContain.append('circle')
    .attr('class', 'pulseDot')
    .attr('cx', d => projection(datum.loc)[0])
    .attr('cy', d => projection(datum.loc)[1])
    .attr('r', window.innerWidth > 2300 ? 15 : 10)
    .style('stroke', 'yellow')
    .call(pulseTrans, timeTrans, timeDelay);

  svgContain.append('circle')
    .attr('class', 'staticDot')
    .attr('cx', d => projection(datum.loc)[0])
    .attr('cy', d => projection(datum.loc)[1])
    .attr('r', window.innerWidth > 2300 ? 15 : 10)
    .style('fill', 'yellow')
    .style('stroke', '#212121');
}

function updatePulseCircle(svgContain, datum, projection) {
  svgContain.select('circle.pulseDot')
    .attr('cx', d => projection(datum.loc)[0])
    .attr('cy', d => projection(datum.loc)[1])

  svgContain.select('circle.staticDot')
    .attr('cx', d => projection(datum.loc)[0])
    .attr('cy', d => projection(datum.loc)[1])
}

function pulseTrans(selection, transDur, transDelay) {
  selection
    .attr('r', '0px')
    .style('stroke-opacity', 1)
    .style('fill-opacity', 1)
    .transition()
    .delay((d, i) => i * transDelay)
    .duration(transDur)
    .attr('r', '30px')
    .style('stroke-width', '5px')
    .style('stroke-opacity', 0)
    .style('fill-opacity', 0)
    .on('end', function(d, i){
      d3.select(this)
        .call(pulseTrans, transDur, 0);
    });
}

function getD3(map) {
  // get important data for the map that will be helpful to define D3 projection
  const bbox = document.getElementById('mapContainer').getBoundingClientRect(); // might need revision
  const center = map.getCenter();
  const zoom = map.getZoom();
  // 512 is hardcoded tile size, might need to be 256 or changed to suit your map config

  const getD3projScal = (tileSize, zoom) => (tileSize) * 0.5 / Math.PI * Math.pow(2, zoom);
  const scale = getD3projScal(512, zoom);

  const projection = d3.geoMercator()
    .center([center.lng, center.lat])
    .translate([bbox.width/2, bbox.height/2])
    .scale(scale)
    .rotate([0,0]);
  return projection;
}

function render(mapObj, routeData, svgContain, locationData, stepIdx, nIdx) {
  // getting the current map projection using mapbox state
  if (document.getElementById('mapContainer')) {
    const d3Projection = getD3(mapObj);

    svgContain.selectAll('path.routes')
      .attr('d', feature => {
        const projCoords = feature.coordinates.map(coordinate => d3Projection(coordinate));
        return smoothPath(`M${projCoords.join("L")}`, d3.curveBasis)
      });

    updatePulseCircle(svgContain, locationData[stepIdx%nIdx], d3Projection);
  }
};

function smoothPath(pathStr, smootherFunc){
  const interpolate = d3.line().curve(smootherFunc);
  const sp = pathStr.replaceAll(/M|Z/g, '').split('L').map((d) => d.split(','));
  return interpolate(sp);
}

export {
  drawRoute,
  killRoutes,
  drawPulseCircle,
  updatePulseCircle,
  pulseTrans,
  getD3,
  render
};
