// Array that stores different stops on the route
const locsAndZoom = [
  {
    loc: [105.4901, 10.3327],
    zoom: 5.0,
    pathIdx: null,
    txTime: 2500,
    pathLength: null
  },
  {
    loc: [105.4901, 10.3327],
    zoom: 9.0,
    pathIdx: null,
    txTime: 2500,
    pathLength: null
  },
  {
    loc: [105.0786, 10.0045],
    zoom: 8.8,
    pathIdx: 0,
    txTime: 1500,
    pathLength: 503.4366149902344
  },
  {
    loc: [100.6132, 7.1782],
    zoom: 7.0,
    pathIdx: 1,
    txTime: 3000,
    pathLength: 3905.63232421875
  },
  {
    loc: [100.5026, 13.7452],
    zoom: 6.5,
    pathIdx: 2,
    txTime: 2400,
    pathLength: 6382.37548828125
  },
  {
    loc: [103.8998, 1.2966],
    zoom: 7.0,
    pathIdx: 3,
    txTime: 3500,
    pathLength: 10602.6689453125
  },
  {
    loc: [104.2843, 0.7195],
    zoom: 8.0,
    pathIdx: 4,
    txTime: 1500,
    pathLength: 590.1972045898438
  },
  {
    loc: [103.8998, 1.2966],
    zoom: 5.0,
    pathIdx:  null,
    txTime: 1500,
    pathLength: null
  },
  {
    loc: [-122.2729, 37.8037],
    zoom: 4.0,
    pathIdx: null,
    txTime: 2500,
    pathLength: null
  },
  {
    loc: [-122.3036, 47.5324],
    zoom: 5.5,
    pathIdx: null,
    txTime: 1500,
    pathLength: null
  },
  {
    loc: [2.3522, 48.8566],
    zoom: 5.0,
    pathIdx: null,
    txTime: 2500,
    pathLength: null
  },
  {
    loc: [30.05885, -1.94995, ],
    zoom: 7.5,
    pathIdx: null,
    txTime: 2500,
    pathLength: null
  },
  {
    loc: [44.1910, 15.3694],
    zoom: 6.5,
    pathIdx: null,
    txTime: 2000,
    pathLength: null
  },
  {
    loc: [-69.9670, 43.9140],
    zoom: 7.0,
    pathIdx: null,
    txTime: 2000,
    pathLength: null
  },
  {
    loc: [-74.6672, 40.3573],
    zoom: 7.8,
    pathIdx: null,
    txTime: 2000,
    pathLength: null
  }
];

export {locsAndZoom}
