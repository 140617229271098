import { useState } from 'react';
import styles from './css-modules/navbar.module.css';
import { FaInstagram, FaFacebookF, FaTwitter} from 'react-icons/fa'
import { HiMenuAlt2 } from "react-icons/hi";
import { IoCloseSharp } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { Fade } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Navbar(props) {
  const [mobileNav, setMobileNav] = useState(false);
  const [alertOpen, setAlterOpen] = useState(false);

  const handleClickOpen = () => {
    setAlterOpen(true);
  };

  const handleClose = () => {
    setAlterOpen(false);
  };

  return (
    <div className={styles.navbar_container}>
      <div className={styles.logo_container}>
        <Link style={{textDecoration: 'none'}} to="/">
          <img src={props.images.logo} alt="" className={styles.logo}/>
        </Link>
      </div>
      <div className={styles.nav_container}>
        <Link style={{textDecoration: 'none'}} to="/about">
          <div className={styles.nav_link}>
            <p className={styles.link_text}>About</p>
          </div>
        </Link>
        <Link style={{textDecoration: 'none'}} to="/contact">
          <div className={styles.nav_link}>
            <p className={styles.link_text}>Contact</p>
          </div>
        </Link>
        <button className={styles.nav_link_button} onClick={() => handleClickOpen()}>
          <p className={styles.link_text_button}>Buy</p>
        </button>
      </div>
      <div className={styles.social_container}>
        <div className={styles.social_icons_container}>
          <FaInstagram onClick={() => window.open('https://www.instagram.com/mai_unplugged/')} className={styles.social_icon}/>
          <FaFacebookF onClick={() => window.open('https://www.facebook.com/Mai.Unplugged/')} className={styles.social_icon}/>
          <FaTwitter onClick={() => window.open('https://twitter.com/mai_unplugged')} className={styles.social_icon}/>
          <HiMenuAlt2 onClick={() => setMobileNav(true)} className={styles.menu_mobile_icon}/>
        </div>
      </div>

      <Fade in={mobileNav}>
        <div className={styles.mobile_nav}>
          <div className={styles.background_container_mob}>
          </div>
          <div className={styles.mobile_painting_cont}>
            <img className={styles.mobile_painting} src={props.images.painting}></img>
          </div>
          <div className={styles.mobile_links_cont}>
            <Link style={{textDecoration: 'none'}} to="/about">
              <div className={styles.nav_link_mob}>
                <p className={styles.link_text_mob}>About</p>
              </div>
            </Link>
            <Link style={{textDecoration: 'none'}} to="/contact">
              <div className={styles.nav_link_mob}>
                <p className={styles.link_text_mob}>Contact</p>
              </div>
            </Link>
            <button className={styles.nav_link_button_mob} onClick={() => handleClickOpen()}>
              <p className={styles.link_text_button_mob}>Buy</p>
            </button>
          </div>
          <div className={styles.close_container}>
            <div onClick={() => setMobileNav(false)} className={styles.close_icon_container}>
              <IoCloseSharp size={26} className={styles.close_mobile_icon}/>
            </div>
          </div>
        </div>
      </Fade>


      <Dialog
       open={alertOpen}
       onClose={handleClose}
       aria-labelledby="buy-worlds-apart"
       aria-describedby="Stores where worlds apart can be bought"
      >
       <DialogTitle id="alert-dialog-title">
         {"Buy Worlds Apart"}
       </DialogTitle>
       <DialogContent>
         <DialogContentText id="stores-to-buy-worlds-apart">
           <div className={styles.navigation_buy_stores_container}>
             <div onClick={() => window.open('https://www.amazon.com/dp/1954968906/')} className={styles.store_container}>
               <img className={styles.store_logo} src='https://res.cloudinary.com/worlds-apart/image/upload/v1641446472/store_logos/amazon_gh1wsq.png'></img>
               <p className={styles.store_name}>Amazon</p>
               <p className={styles.store_type}>Buy Online</p>
             </div>
             <div onClick={() => window.open('https://store.bowdoin.edu/products/worlds-apart-le-00?_pos=1&_sid=7bdd59a20&_ss=r')} className={styles.store_container}>
               <img className={styles.store_logo} src='https://res.cloudinary.com/worlds-apart/image/upload/v1641446472/store_logos/bowdoin_uassiw.png'></img>
               <p className={styles.store_name}>Bowdoin Bookstore</p>
               <p className={styles.store_type}>Buy Online</p>
             </div>
             <div onClick={() => window.open('https://www.brooklinebooksmith.com/book/9781954968905')} className={styles.store_container}>
               <img className={styles.store_logo} src='https://res.cloudinary.com/worlds-apart/image/upload/v1641446472/store_logos/brookline_gvirg4.png'></img>
               <p className={styles.store_name}>Brookline Booksmith</p>
               <p className={styles.store_type}>Buy Online</p>
             </div>
             <div onClick={() => window.open('https://www.lowellbookcompany.com/')} className={styles.store_container}>
               <img className={styles.store_logo} src='https://res.cloudinary.com/worlds-apart/image/upload/v1641446472/store_logos/Lowell_j8jeps.png'></img>
               <p className={styles.store_name}>Lowell Book Company</p>
               <p className={styles.store_type}>In Store Only</p>
             </div>
           </div>
         </DialogContentText>
       </DialogContent>
     </Dialog>
    </div>
  );
}

export default Navbar;
