import { useRef, useEffect } from 'react';
import styles from './css-modules/footer.module.css';
import { FaInstagram, FaFacebookF, FaTwitter} from 'react-icons/fa'
import { TweenMax, Sine} from 'gsap';
import classNames from 'classnames';
import copyright from '../resources/pdf/Copyright Notice.pdf';
import terms from '../resources/pdf/Worlds Apart Website Terms of Use.pdf';
import privacy from '../resources/pdf/Worlds Apart Privacy Policy.pdf';

function onMouseMove(factorX, factorY, items){
    items.forEach((item, i) => {
        if(item.el.current) {
          let x = Math.round(0.5 * item.shift * Math.round(factorX * 100) / 100) * 2;
          let y = Math.round(0.5 * Math.max(-50, item.shift / 4 * (Math.round(factorY * 100) / 100))) * 2;
          TweenMax.to(item.el.current, 2, {
              x: x,
              y: y,
              ease: Sine.easeOut
          });
        }
    });
}

function Footer(props) {

  const imageRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    containerRef.current.addEventListener('mousemove', (event)=>{
      const factorX = -2 * (( event.clientX / window.innerWidth) - 0.5);
      const factorY = -2 * (( (event.clientY - 80) / (window.innerHeight - 80)) - 0.5);
      onMouseMove(factorX, factorY, [{el : imageRef, shift : 15}]);
    });
  }, []);

  return (
    <div ref={containerRef} className={styles.footer_container}>
      <div className={styles.background_container}>
        <div className={styles.skewed_color}>
        </div>
        <img ref={imageRef} className={styles.coverBG} src={props.images.footerBg} alt="" />
      </div>
      <div className={styles.footer_content_container}>
        <div className={styles.image_container}>
          <img src={props.images.logo} className={styles.logo_container}></img>
        </div>
        <div className={styles.footer_links_container}>
          <div className={styles.footer_links}>
            <p onClick={() => window.open(copyright)} className={classNames(styles.footer_link, "Lato")}>Copyright Notice</p>
            <p onClick={() => window.open(terms)} className={classNames(styles.footer_link, "Lato")}>Terms of Use</p>
            <p onClick={() => window.open(privacy)} className={classNames(styles.footer_link, "Lato")}>Privacy Policy</p>
          </div>
          <div className={styles.social_icons}>
            <FaInstagram onClick={() => window.open('https://www.instagram.com/mai_unplugged/')} className={styles.social_icon}/>
            <FaFacebookF onClick={() => window.open('https://www.facebook.com/Mai.Unplugged/')} className={styles.social_icon}/>
            <FaTwitter onClick={() => window.open('https://twitter.com/mai_unplugged')} className={styles.social_icon}/>
          </div>
          <p className={styles.copyright_message}>Copyright © {new Date().getFullYear()} Mai Kim Le</p>
          <button onClick={() => {window.open('https://www.amazon.com/dp/1954968906/')}} className={styles.footer_buy_button}>Buy</button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
