import {useEffect } from 'react';
import Navbar from '../components/navbar.js';
import PageTitle from '../components/pageTitle.js';
import Loader from "react-loader-spinner";
import { Fade } from '@material-ui/core';
import Footer from '../components/footer.js';
import commonStyles from './css-modules/common.module.css';
import styles from './css-modules/about.module.css';
import classNames from 'classnames';
import ReactGA from 'react-ga4';
import DocumentMeta from 'react-document-meta';

function About(props) {

  const meta = {
    title: 'Worlds Apart | About Mai',
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about" });
  }, []);

  return (
    <div>
      <DocumentMeta {...meta}/>
      {!props.dataLoadad ?
      <div className="data_loader">
        <Loader
          type="TailSpin"
          color="#2D545D"
          height={60}
          width={60}
          timeout={3000} //3 secs
        />
      </div>
      :
      <Fade in={props.dataLoadad}>
        <div>
          <Navbar socialMedia={props.landingData.socialMedia} images={props.images}/>
          <PageTitle title={props.aboutData.title} images={props.images}/>
          <div className={commonStyles.content_container}>
            <div className={styles.about_section}>
              <div className={styles.image_section}>
                <img id="photo_0" className={styles.about_photo_fade} src={props.images.aboutPhoto} />
                <img id="photo_1" className={styles.about_photo_fade} src={props.images.aboutPhoto1} />
                <img id="photo_2" className={styles.about_photo_fade} src={props.images.aboutPhoto2} />
              </div>
              <div className={styles.mobile_image_section}>
                <img className={styles.about_photo_mobile} src={props.images.mobileAbout} />
              </div>
              <div className={styles.text_container}>
                <h2 className={classNames('Lato', styles.mai_name)}>Mai Kim Le</h2>
                <div className={styles.separator}></div>
                <div className={styles.about_paras} dangerouslySetInnerHTML={{ __html: props.aboutData.about.aboutparas }} />
              </div>
            </div>
          </div>
          <Footer socialMedia={props.landingData.socialMedia} images={props.images}/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default About;
