import {useEffect, useState } from 'react';
import axios from 'axios';
import Home from './views/home.js';
import About from './views/about.js';
import Contact from './views/contact.js';

import * as d3 from 'd3';
import maiRoutes from './components/map/MaiRoutes.geojson';

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import ReactGA from "react-ga4";

function App() {
  // useEffect equivalent of ComponentDidMount

  const [landingData, setLandingDataState] = useState({});
  const [aboutData, setAboutDataState] = useState({});
  const [mapLocationsData, setMapLocationsDataState] = useState([]);
  const [loadedData, setLoadedDataState] = useState(false);
  const [geoData, setGeoData] = useState(null);
  const [geoDataTx, setGeoDataTx] = useState(null);
  const [d3dataLoaded, setD3dataLoaded] = useState(false);

  const painting = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631319317/painting_jkwlbh.jpg';
  const brush = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631319317/brush_sr9qmt.png';
  const brushedAlum = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631319317/brushed-alum-dark_k2qj1r.png';
  const logo = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631319317/logo_ziggqo.png';
  const footerBg = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631319317/bookCover_BG_gh0225.png';
  const aboutPhoto = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631598912/mai_-_book_-_full_image_1_iidbha.jpg';
  const aboutPhoto1 = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631598880/JPG-Med-JoeLeeFoto-2021-06-18_MaiLe-Portrait-0533_1_ctneub.jpg';
  const aboutPhoto2 = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631598880/JPG-Med-JoeLeeFoto-2021-06-18_MaiLe-Portrait-0856_1_wc2ceh.jpg';
  const mobileAbout = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631637294/mai_-_book_-_500x500_yjcsj3.jpg';
  const contactPhoto = 'https://res.cloudinary.com/worlds-apart/image/upload/v1631652981/19197471_1_hvppot.jpg';

  useEffect(() => {
    const imageArr = [brush, brushedAlum, logo, painting, footerBg, aboutPhoto, aboutPhoto1, aboutPhoto2, mobileAbout, contactPhoto];

    imageArr.forEach((image) => {
      new Image().src = image
    });

    axios({
      url: 'https://maikimle.com/wordpress/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            landings {
              nodes {
                title
                subtitle { authorName excerpt subtitle }
                socialMedia { facebook instagram twitter }
                preTitle { exploreText }
                buyBook { linkToBuyBook }
              }
            }
            allAboutPage {
              nodes {
                title
                about {
                  aboutparas
                }
              }
            }
            mapLocations(first: 20) {
              nodes {
                title
                data {
                  description
                  locationid
                  images
                }
              }
            }
          }
        `
      }
    }).then((result) => {
      setLandingDataState(result.data.data.landings.nodes[0]);
      setAboutDataState(result.data.data.allAboutPage.nodes[0]);

      let mapLocations = result.data.data.mapLocations.nodes.sort(function(a, b) { return a.data.locationid - b.data.locationid });
      mapLocations[0].data.locationid = 0;

      for (let i = 0; i < mapLocations.length; i++) {
        if (mapLocations[i].data.images) {
          mapLocations[i].data.images = mapLocations[i].data.images.split(",");
        }
        else {
          mapLocations[i].data.images = [];
        }
      }
      setMapLocationsDataState(mapLocations);
      setLoadedDataState(true);
    });

    if (!geoData){
      d3.json(maiRoutes).then((data) => {
        setGeoData(data);

        const routeFeatures = data.features.map(feature => ({
                                      properties: feature.properties,
                                      coordinates: feature.geometry.coordinates[0]
                                    }))
                                    .sort((a, b) => a.properties.id - b.properties.id);


        setGeoDataTx(routeFeatures);
        setD3dataLoaded(true);

      });
    }
    ReactGA.initialize("G-7MNYS7NS5P");
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/"
          render={(props) => (
            <Home
              dataLoadad={d3dataLoaded && loadedData}
              geoData={geoData}
              geoDataTx={geoDataTx}
              mapLocationsData={mapLocationsData}
              landingData={landingData}
              images = {{painting: painting, brush: brush, brushedAlum: brushedAlum, logo: logo, footerBg: footerBg}}
            />
          )}
        />
        <Route exact path="/about"
          render={(props) => (
            <About
              dataLoadad={d3dataLoaded && loadedData}
              landingData={landingData}
              aboutData={aboutData}
              images = {{painting: painting, logo: logo, footerBg: footerBg, aboutPhoto: aboutPhoto, aboutPhoto1: aboutPhoto1, aboutPhoto2: aboutPhoto2, mobileAbout: mobileAbout}}
            />
          )}
        />
      <Route exact path="/contact"
          render={(props) => (
            <Contact
              dataLoadad={d3dataLoaded && loadedData}
              landingData={landingData}
              images = {{painting: painting, brush: brush, brushedAlum: brushedAlum, logo: logo, footerBg: footerBg, contactPhoto: contactPhoto}}
            />
          )}
        />
      </Switch>
    </div>
  );
}

export default App;
