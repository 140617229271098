import styles from './css-modules/page_title.module.css';
import classNames from 'classnames';

function PageTitle(props) {

  return (
    <div className={styles.title_container}>
      <div className={styles.background_container}>
      </div>
      <div className={styles.painting_container}>
        <img className={styles.painting_image} src={props.images.painting}/>
      </div>
      <div className={styles.title_text_container}>
        <h1 className={classNames(styles.page_title, "Lato")}>{props.title}</h1>
      </div>
    </div>
  );
}

export default PageTitle;
